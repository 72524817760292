import type { MutationFunctionOptions } from '@apollo/client';
import { useApolloClient } from '@apollo/client';
import Button from '@aurora/shared-client/components/common/Button/Button';
import {
  ButtonVariant,
  LoadingButtonVariant
} from '@aurora/shared-client/components/common/Button/enums';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import useFrameEnd, {
  getParentFrameId
} from '@aurora/shared-client/components/context/AnalyticsParentFrames/useFrameEnd';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import useMessagePolicies from '@aurora/shared-client/components/messages/useMessagePolicies';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { ModerationAction } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import dynamic from 'next/dynamic';
import React, { useCallback, useContext, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import type {
  MarkMessageAsSpamOrHamMutation,
  MarkMessageAsSpamOrHamMutationVariables
} from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import spamMessagesQuery from '../MessageListForContentManagement/SpamMessages.query.graphql';
import type { MessageActionType } from '../types';
import markMessageAsSpamMutation from './MarkMessageAsSpamOrHam.mutation.graphql';
import moderationDataQuery from './MessageModerationData.query.graphql';

const ConfirmationDialog = dynamic(
  () => import('@aurora/shared-client/components/common/ConfirmationDialog/ConfirmationDialog'),
  { ssr: false }
);

interface Props extends MessageActionType {
  /**
   * Whether the component is rendered from the Manage Content Dashboard.
   */
  isDashboardAction?: boolean;
  /**
   * Whether the component is rendered from the message subject modal.
   */
  isModalAction?: boolean;
  /**
   * Callback function for toggling the display of subject overview modal.
   */
  onCloseSubjectModal?: () => void;
  /**
   * Callback function for hiding the subject overview modal.
   */
  onHideSubjectModal?: (isHideModal: boolean) => void;
}

/**
 * Action to mark a message as spam.
 *
 * @constructor
 *
 * @author Amit Agrawal
 */
const MessageActionMarkAsSpam: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  isDashboardAction = false,
  isModalAction = false,
  onCloseSubjectModal,
  onHideSubjectModal
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_MARK_AS_SPAM
  );
  const [markMessageAsSpam] = useMutationWithTracing<
    MarkMessageAsSpamOrHamMutation,
    MarkMessageAsSpamOrHamMutationVariables
  >(module, markMessageAsSpamMutation);
  const [frameEnd] = useFrameEnd();
  const { isAnonymous } = useRegistrationStatus();
  const tenant = useContext(TenantContext);
  const { addToast } = useToasts();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const client = useApolloClient();

  const { data: policiesData, loading: policiesLoading } = useMessagePolicies(
    module,
    {
      id: message.id,
      useCanModerateMessage: true
    },
    isAnonymous || !message || IdConverter.isOptimistic(tenant, message?.id)
  );
  const canModerateMessage = checkPolicy(
    policiesData?.message?.messagePolicies?.canModerateSpamMessage
  );

  /**
   * Renders an error banner on failure of moderateMessageMutation call displaying the error response.
   */
  const renderErrorFeedback = useCallback(
    (error): void => {
      const toastProps: ToastProps = {
        toastVariant: ToastVariant.BANNER,
        alertVariant: ToastAlertVariant.DANGER,
        title: error.name,
        message: error.message,
        autohide: false,
        id: `MarkAsSpam-Error-${error.name}`
      };
      addToast(toastProps);
    },
    [addToast]
  );

  /**
   * Renders a success alert on action confirmation.
   */
  const renderSuccessFeedback = useCallback((): void => {
    const toastProps: ToastProps = {
      toastVariant: ToastVariant.FLYOUT,
      alertVariant: ToastAlertVariant.SUCCESS,
      title: formatMessage('successTitle.markAsSpam'),
      message: formatMessage('successMessage.markAsSpam'),
      autohide: true,
      delay: 4000,
      id: 'MarkAsSpam-success'
    };
    addToast(toastProps);
  }, [addToast, formatMessage]);

  if (isAnonymous || textLoading || policiesLoading) {
    return null;
  }

  /**
   * Action handler for marking a message as spam.
   */
  async function handleAction(): Promise<void> {
    let rollback;
    const mutationPayload: MarkMessageAsSpamOrHamMutationVariables = {
      messageId: message.id,
      action: ModerationAction.Spam
    };
    const parentFrameId = getParentFrameId();

    const mutationOptions: MutationFunctionOptions<
      MarkMessageAsSpamOrHamMutation,
      MarkMessageAsSpamOrHamMutationVariables
    > = {
      variables: mutationPayload,
      refetchQueries: [
        {
          query: moderationDataQuery,
          variables: {
            id: message.id
          }
        },
        {
          query: spamMessagesQuery
        }
      ],
      onCompleted: (): void => {
        frameEnd({
          context: { parentFrameId }
        });
      },
      context: { parentFrameId }
    };

    const { errors } = await markMessageAsSpam(mutationOptions);
    client.cache.evict({ id: client.cache.identify(message) });
    client.cache.gc();

    if (errors?.length > 0) {
      if (rollback) {
        rollback();
      }
      errors.forEach(error => renderErrorFeedback(error));
    } else if (isDashboardAction) {
      if (isModalAction) {
        setTimeout(() => onHideSubjectModal(false), 500);
        onCloseSubjectModal();
      }
      renderSuccessFeedback();
    }
    setShowConfirmModal(false);
    return;
  }

  function renderConfirmationDialog() {
    return (
      isDashboardAction &&
      showConfirmModal && (
        <ConfirmationDialog
          show={showConfirmModal}
          onHide={(): void => {
            setShowConfirmModal(false);
            if (isModalAction) {
              setTimeout(() => onHideSubjectModal(false), 500);
              onCloseSubjectModal();
            }
          }}
          onSubmit={handleAction}
          onCancel={(): void => {
            if (isModalAction) {
              setTimeout(() => onHideSubjectModal(false), 500);
              onCloseSubjectModal();
            }
            setShowConfirmModal(false);
          }}
          titleText={formatMessage('markAsSpam')}
          bodyText={formatMessage('confirmationModalBody.markAsSpam')}
          submitButtonType={LoadingButtonVariant.LOADING_BUTTON}
          submitButtonText={formatMessage('mark')}
        />
      )
    );
  }

  function renderActionButtonOrDropdownItem() {
    const formattedActionText = formatMessage('markAsSpam');

    return isModalAction ? (
      <Button
        size="lg"
        variant={ButtonVariant.LIGHT}
        onClick={() => {
          onHideSubjectModal(true);
          setShowConfirmModal(true);
        }}
      >
        {formattedActionText}
      </Button>
    ) : (
      canModerateMessage && (
        <Dropdown.Item
          onClick={isDashboardAction ? () => setShowConfirmModal(true) : () => handleAction()}
          data-testid="MessageActionMarkAsSpam"
        >
          {formattedActionText}
        </Dropdown.Item>
      )
    );
  }

  return (
    <>
      {renderActionButtonOrDropdownItem()}
      {renderConfirmationDialog()}
    </>
  );
};

export default MessageActionMarkAsSpam;
