import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import UserAvatar from '@aurora/shared-client/components/users/UserAvatar/UserAvatar';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import type { ReactElement } from 'react';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { UserKudosType } from '../../../types/enums';
import type { UserViewFragment } from '../../../types/graphql-types';
import UserKudos from '../../users/UserKudos/UserKudos';
import UserMessagesCount from '../../users/UserMessagesCount/UserMessagesCount';
import UserSolutionCount from '../../users/UserSolutionCount/UserSolutionCount';
import useTranslation from '../../useTranslation';
import localStyles from './UserDetails.module.pcss';

interface Props {
  /**
   * The ban user
   */
  banUser: UserViewFragment;
}

/**
 * Renders Ban user details for BanUserForm
 *
 * @author Janani A
 */
const UserDetails: React.FC<React.PropsWithChildren<Props>> = ({ banUser }): ReactElement => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(EndUserComponent.USER_DETAILS);

  if (textLoading) {
    return null;
  }

  return (
    <div className={cx('border-bottom')} data-testid="BanUserDetails">
      <h5>{formatMessage('memberDetails')}</h5>
      <div className={cx('d-flex flex-row lia-g-pt-15 lia-g-pb-25')}>
        <UserAvatar className={cx('lia-g-mr-15')} user={banUser} size={IconSize.PX_40}></UserAvatar>
        <div className={cx('d-flex flex-column')}>
          <span>{banUser?.login}</span>
          <div className={cx('lia-meta')}>
            <UserMessagesCount
              user={banUser}
              className={cx('lia-g-divider')}
              textClassName={cx('lia-info')}
              countClassName={cx('lia-info')}
            />
            <UserKudos
              className={cx('lia-g-divider')}
              textClassName={cx('lia-info')}
              countClassName={cx('lia-info lia-count')}
              user={banUser}
              useText={false}
              useTable
              userKudosType={UserKudosType.RECEIVED}
            />
            <UserSolutionCount
              className={cx('lia-g-divider')}
              textClassName={cx('lia-info')}
              countClassName={cx('lia-info')}
              useText={true}
              useTable={false}
              user={banUser}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
