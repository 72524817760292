/**
 * The message action email user variant enum
 */
export enum MessageActionEmailUserVariant {
  /**
   * variant of type dropdown
   */
  DROPDOWN = 'dropdown',
  /**
   * variant of type button
   */
  BUTTON = 'button'
}
