import type { Board } from '@aurora/shared-generated/types/graphql-schema-types';
import { ContentWorkflowState } from '@aurora/shared-generated/types/graphql-schema-types';
import { MessageEditorFormAction, MessageState } from '../../types/enums';
import type {
  ContextMessageContentWorkflowFragment,
  ContextNodeFragment,
  MessageActionMenuFragment,
  MessageContentWorkflowFragment
} from '../../types/graphql-types';

const defaultActions: Array<MessageEditorFormAction> = [
  MessageEditorFormAction.DRAFT,
  MessageEditorFormAction.CANCEL
];

/**
 * Actions for form and toolbar when Content Workflow is enabled.
 */
interface Actions {
  /**
   * Footer form actions.
   */
  formActions: Array<MessageEditorFormAction>;
  /**
   * Toolbar button actions for message.
   */
  toolbarActions: Array<MessageEditorFormAction>;
  /**
   * Text for state of message.
   */
  messageState: string;
}

/**
 * Helper for working with Content Workflow actions.
 * @author Aditi Agarwal
 */

/**
 * Returns the actions to be displayed when content workflow is enabled.
 */
export function getContentWorkflowEnabledActions(
  contextMessage: Pick<
    ContextMessageContentWorkflowFragment,
    'contentWorkflow' | 'messagePolicies'
  >,
  contextNode?: ContextNodeFragment
): Actions {
  const userPermission = contextMessage?.contentWorkflow?.userContext;
  const messageCurrentState = contextMessage?.contentWorkflow?.state;
  const canPostMessage = contextNode?.userContext?.canPostMessages;
  const canPublishArticleOnCreate =
    (contextNode as Board)?.boardPolicies?.canPublishArticleOnCreate?.failureReason == null;
  const canPublishArticleOnEdit =
    contextMessage?.messagePolicies?.canPublishArticleOnEdit?.failureReason == null;
  const formActions: MessageEditorFormAction[] = [];
  const toolbarActions: MessageEditorFormAction[] = [];
  let messageState;

  const statePermissionMap = {
    [ContentWorkflowState.Draft]: ['canPublish', 'canSubmitForReview', 'canEdit'],
    [ContentWorkflowState.InReview]: [
      'canPublish',
      'canSubmitForPublication',
      'canReturnToAuthor',
      'canEdit',
      'canRecall'
    ],
    [ContentWorkflowState.InApproval]: [
      'canPublish',
      'canSchedule',
      'canReturnToReview',
      'canRecall'
    ],
    [ContentWorkflowState.ScheduledForPublish]: ['canRecall']
  };

  const permissionButtonMap = {
    canSubmitForReview: MessageEditorFormAction.SUBMIT_FOR_REVIEW,
    canEdit: MessageEditorFormAction.EDIT_DRAFT,
    canSubmitForPublication: MessageEditorFormAction.SUBMIT_FOR_PUBLICATION,
    canReturnToAuthor: MessageEditorFormAction.RETURN_TO_AUTHOR,
    canRecall: MessageEditorFormAction.RECALL,
    canPublish: MessageEditorFormAction.PUBLISH,
    canSchedule: MessageEditorFormAction.SCHEDULE_PUBLISH,
    canReturnToReview: MessageEditorFormAction.RETURN_TO_REVIEW
  };

  /**
   * Returns the footer and toolbar actions to be displayed in different message states.
   */
  function getActionsInContentWorkflowApprovalState(
    contentWorkflowMessageState: ContentWorkflowState
  ): void {
    const tempFormActions: MessageEditorFormAction[] = [];
    if (canPublishArticleOnEdit) {
      // Adding publish action to the form for any user who can publish while editing the revision
      tempFormActions.push(MessageEditorFormAction.PUBLISH);
    }
    // Adding default actions on the form based on each workflow state
    switch (contentWorkflowMessageState) {
      case ContentWorkflowState.Draft:
      case ContentWorkflowState.Publish: {
        tempFormActions.push(MessageEditorFormAction.SUBMIT_FOR_REVIEW);
        break;
      }
      case ContentWorkflowState.InReview: {
        tempFormActions.push(MessageEditorFormAction.SUBMIT_FOR_PUBLICATION);
        messageState = MessageState.AWAITING_REVIEW;
        break;
      }
      case ContentWorkflowState.InApproval: {
        messageState = MessageState.AWAITING_PUBLICATION;
        break;
      }
    }
    statePermissionMap[contentWorkflowMessageState]?.forEach(permission => {
      if (userPermission[permission]) {
        // Adding form actions other than the default based on user's permissions
        tempFormActions.push(permissionButtonMap[permission]);
        toolbarActions.push(permissionButtonMap[permission]);
      }
    });
    // Using Set to remove any duplicates
    formActions.push(...new Set(tempFormActions), ...defaultActions);
  }

  if (userPermission) {
    getActionsInContentWorkflowApprovalState(messageCurrentState);
  } else if (canPostMessage) {
    if (canPublishArticleOnCreate) {
      formActions.push(MessageEditorFormAction.PUBLISH);
    }
    formActions.push(MessageEditorFormAction.SUBMIT_FOR_REVIEW, ...defaultActions);
  }
  return { formActions, toolbarActions, messageState };
}

/**
 * Returns the actions to be displayed when content workflow is disabled.
 */
export function getContentWorkflowDisabledActions(
  contextMessage: Pick<ContextMessageContentWorkflowFragment, 'contentWorkflow'>
): Array<MessageEditorFormAction> {
  const messageCurrentState = contextMessage?.contentWorkflow?.state;
  const actions = [];
  if (messageCurrentState !== ContentWorkflowState.ScheduledForPublish) {
    actions.push(MessageEditorFormAction.PUBLISH, MessageEditorFormAction.EDIT_DRAFT);
  } else {
    actions.push(
      MessageEditorFormAction.EDIT_DRAFT,
      MessageEditorFormAction.EDIT_SCHEDULE,
      MessageEditorFormAction.REMOVE
    );
  }
  return actions;
}

/**
 * Check whether the message is in draft or not.
 *
 * @param message The message that the user is viewing currently.
 *
 * @returns boolean whether the message is in draft or not.
 */
export function isDraft(message: MessageActionMenuFragment): boolean {
  return (
    (message as MessageContentWorkflowFragment).contentWorkflow?.state ===
      ContentWorkflowState.Draft ||
    (message as MessageContentWorkflowFragment).contentWorkflow?.state ===
      ContentWorkflowState.InReview ||
    (message as MessageContentWorkflowFragment).contentWorkflow?.state ===
      ContentWorkflowState.InApproval ||
    (message as MessageContentWorkflowFragment).contentWorkflow?.state ===
      ContentWorkflowState.ScheduledForPublish
  );
}
