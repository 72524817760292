import type { MutationFunctionOptions } from '@apollo/client';
import { useApolloClient } from '@apollo/client';
import Button from '@aurora/shared-client/components/common/Button/Button';
import type { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { LoadingButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import ContentWorkflowQuery from '@aurora/shared-client/components/contentWorkflow/ContentWorkflow.query.graphql';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import type { MessageEditPagesAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type {
  Board,
  RevisionConnection,
  UpdateBlogArticleInput,
  UpdateTkbArticleInput
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  ContentWorkflowState,
  ConversationStyle,
  PostMessageType,
  WorkflowAction
} from '@aurora/shared-generated/types/graphql-schema-types';
import type {
  ContentWorkflowPropertiesQuery,
  ContentWorkflowPropertiesQueryVariables,
  ContextMessageFragment
} from '@aurora/shared-generated/types/graphql-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import dynamic from 'next/dynamic';
import React, { useContext, useState } from 'react';
import { Dropdown, useClassNameMapper } from 'react-bootstrap';
import ConversationStyleBehaviorHelper from '../../../helpers/boards/ConversationStyleBehaviorHelper';
import type { MessageEditingState } from '@aurora/shared-client/helpers/ui/GlobalState';
import useGlobalState, {
  EditorLocation,
  GlobalStateType
} from '@aurora/shared-client/helpers/ui/GlobalState';
import type {
  ContextMessageContentWorkflowFragment,
  UpdateBlogArticleMutation,
  UpdateBlogArticleMutationVariables,
  UpdateTkbArticleMutation,
  UpdateTkbArticleMutationVariables
} from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import updateBlogArticleMutation from '../MessageEditorForm/UpdateBlogArticle.mutation.graphql';
import updateTkbArticleMutation from '../MessageEditorForm/UpdateTkbArticle.mutation.graphql';
import {
  getFirstRevision,
  getRevisionMessage,
  useMessageRevisionsQuery
} from '../useCurrentOrPreviewMessage';

const ConfirmationDialog = dynamic(
  () => import('@aurora/shared-client/components/common/ConfirmationDialog/ConfirmationDialog'),
  { ssr: false }
);

interface Props {
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * variant to apply to the Edit draft button.
   */
  variant?: ButtonVariant;
  /**
   * Flag to specify whether the button will be disabled or not.
   */
  isDisabled?: boolean;
  /**
   * Flag to specify whether the option is a dropdown item or not.
   */
  asDropdownItem?: boolean;
  /**
   * The message of which the schedule is to be removed.
   */
  message?: ContextMessageFragment;
}

/**
 * Action to edit a draft message.
 *
 * @constructor
 *
 * @author Ritu Somani
 */

const MessageActionEditDraft: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  variant,
  isDisabled,
  asDropdownItem = false,
  message
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_EDIT_DRAFT
  );
  const { Link, router } = useEndUserRoutes();
  const cx = useClassNameMapper();
  const { contextNode } = useContext(AppContext);
  let { contextMessage } = useContext(AppContext);
  const [, setMessageEditingState] = useGlobalState(GlobalStateType.MESSAGE_EDITING_STATE);
  const [showModal, setShowModal] = useState<boolean>(false);
  const client = useApolloClient();
  const { addToast } = useToasts();

  if (!contextMessage || message) {
    contextMessage = message;
  }

  const { data, loading } = useMessageRevisionsQuery({
    id: contextMessage?.id,
    revisionsFirst: 1,
    useRevisionMessage: true
  });

  const [updateBlogArticle] = useMutationWithTracing<
    UpdateBlogArticleMutation,
    UpdateBlogArticleMutationVariables
  >(module, updateBlogArticleMutation);

  const [updateTkbArticle] = useMutationWithTracing<
    UpdateTkbArticleMutation,
    UpdateTkbArticleMutationVariables
  >(module, updateTkbArticleMutation);

  const { data: nodeData } = useQueryWithTracing<
    ContentWorkflowPropertiesQuery,
    ContentWorkflowPropertiesQueryVariables
  >(module, ContentWorkflowQuery, {
    variables: { id: contextNode.id }
  });

  const supportsContentWorkflow = checkPolicy(nodeData?.coreNode?.cwaProperties?.cwaEnabled);

  if (loading || !data || textLoading) {
    return null;
  }

  const localEditState: MessageEditingState = {
    messageId: String(contextMessage?.uid),
    postMessageType: PostMessageType.Edit,
    editorLocation: EditorLocation.INLINE
  };
  const { messageEditPage } = ConversationStyleBehaviorHelper.getInstance(contextNode as Board);

  const isScheduledForPublish =
    (contextMessage as ContextMessageContentWorkflowFragment)?.contentWorkflow?.state ===
    ContentWorkflowState.ScheduledForPublish;

  const userContext = (contextMessage as ContextMessageContentWorkflowFragment)?.contentWorkflow
    ?.userContext;

  const revisionMessage =
    data.message.revisions.edges.length > 0
      ? getRevisionMessage(getFirstRevision(data.message.revisions as RevisionConnection))
      : data.message;
  const messageEditParams = {
    boardId: contextMessage.board.displayId,
    messageSubject: String(UrlHelper.determineSlugForMessagePath(revisionMessage)),
    messageId: String(contextMessage?.uid)
  };

  const canEditOnScheduleForPublish = isScheduledForPublish && userContext?.canRecall;

  /**
   *  Displays the toast message for error scenarios.
   */
  function renderError(errorId: string): void {
    const toastProps: ToastProps = {
      alertVariant: ToastAlertVariant.DANGER,
      toastVariant: ToastVariant.BANNER,
      title: formatMessage('errorTitle'),
      message: formatMessage('errorMessage'),
      autohide: true,
      id: `remove-schedule-publish-${errorId}-error`
    };
    addToast(toastProps);
  }

  /**
   *  Handles the cancel action of scheduling a post and routes back to draft view.
   */
  async function handleEditDraftAction(): Promise<void> {
    const updateInput: UpdateBlogArticleInput | UpdateTkbArticleInput = {
      contentWorkflowAction: {
        workflowAction: supportsContentWorkflow
          ? WorkflowAction.Recall
          : WorkflowAction.CancelSchedulePublication,
        isEditAction: false
      }
    };

    if (message?.board?.conversationStyle === ConversationStyle.Blog) {
      const mutationOptions: MutationFunctionOptions<
        UpdateBlogArticleMutation,
        UpdateBlogArticleMutationVariables
      > = {
        variables: {
          id: contextMessage?.id,
          updateInput,
          useContentWorkflow: true
        }
      };

      const { errors } = await updateBlogArticle(mutationOptions);
      if (errors) {
        renderError(contextMessage?.id);
      }
    } else if (message?.board?.conversationStyle === ConversationStyle.Tkb) {
      const mutationOptions: MutationFunctionOptions<
        UpdateTkbArticleMutation,
        UpdateTkbArticleMutationVariables
      > = {
        variables: {
          id: contextMessage?.id,
          updateInput,
          useContentWorkflow: true
        }
      };

      const { errors } = await updateTkbArticle(mutationOptions);
      if (errors) {
        renderError(contextMessage?.id);
      }
    }

    client.cache.evict({ id: client.cache.identify(contextMessage), broadcast: false });
    client.cache.gc();

    await router.pushRoute<MessageEditPagesAndParams>(messageEditPage, messageEditParams);
  }

  /**
   * Renders the confirmation dialog for edit draft
   */
  function renderEditDraftDialog(): React.ReactElement {
    return (
      <ConfirmationDialog
        show={showModal}
        onHide={(): void => setShowModal(false)}
        onSubmit={() => handleEditDraftAction()}
        onCancel={(): void => setShowModal(false)}
        titleText={formatMessage('editDraft')}
        bodyText={formatMessage('editDraftModalBody')}
        submitButtonType={LoadingButtonVariant.LOADING_BUTTON}
        submitButtonText={formatMessage('editDraft')}
      />
    );
  }

  return userContext?.canEdit && !asDropdownItem ? (
    <Link<MessageEditPagesAndParams>
      route={messageEditPage}
      params={messageEditParams}
      passHref
      legacyBehavior={true}
    >
      <Button
        onClick={(): void => setMessageEditingState(localEditState)}
        type="button"
        variant={variant}
        className={cx(className)}
        disabled={isDisabled}
        data-testid="MessageActionEditDraft.Button"
      >
        {formatMessage('editDraft')}
      </Button>
    </Link>
  ) : canEditOnScheduleForPublish && !asDropdownItem ? (
    <>
      <Button
        onClick={(): void => setShowModal(true)}
        type="button"
        variant={variant}
        className={cx(className)}
        disabled={isDisabled}
        data-testid="MessageActionEditDraft.Button"
      >
        {formatMessage('editDraft')}
      </Button>
      {showModal && renderEditDraftDialog()}
    </>
  ) : canEditOnScheduleForPublish && asDropdownItem ? (
    <>
      <Dropdown.Item onClick={() => setShowModal(true)} data-testid="MessageActionEditDraft.item">
        {formatMessage('editDraft')}
      </Dropdown.Item>
      {showModal && renderEditDraftDialog()}
    </>
  ) : null;
};
export default MessageActionEditDraft;
