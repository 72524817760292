import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import type { User } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import React, { useContext, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import type {
  BanUserPolicyQuery,
  BanUserPolicyQueryVariables,
  UserStatusQuery,
  UserStatusQueryVariables
} from '../../../types/graphql-types';
import BanUserDetails from '../../bans/BanUserDetails/BanUserDetails';
import banUserPolicyQuery from '../../bans/BanUserPolicy.query.graphql';
import useStatusQuery from '../../bans/UserStatus.query.graphql';
import useTranslation from '../../useTranslation';

export interface BanMemberActionProps {
  /**
   * the user to be reported
   */
  user: User;
  /**
   * Whether the component is rendered from a modal.
   */
  isModalAction?: boolean;
  /**
   * Callback function for toggling the display for the modal.
   */
  onCloseSubjectModal?: () => void;
  /**
   * Callback function for hiding the private message subject overview modal.
   */
  onHideSubjectModal?: (isHideModal: boolean) => void;
  /**
   * Variant of the button.
   */
  variant?: ButtonVariant;
}

/**
 * MessageActionBanMember Component
 * Renders ban member menu item on required places.
 *
 * @author Vishnu Das
 */
const MessageActionBanMember: React.FC<React.PropsWithChildren<BanMemberActionProps>> = ({
  user,
  isModalAction = false,
  onCloseSubjectModal,
  onHideSubjectModal,
  variant = ButtonVariant.LIGHT
}) => {
  const [showBanModal, setShowBanModal] = useState<boolean>(false);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_BAN_MEMBER
  );

  const { data: banUserData, loading: banUserLoading } = useQueryWithTracing<
    UserStatusQuery,
    UserStatusQueryVariables
  >(module, useStatusQuery, {
    variables: {
      id: user.id
    }
  });

  const { data: banPoliciesData, loading: banPoliciesLoading } = useQueryWithTracing<
    BanUserPolicyQuery,
    BanUserPolicyQueryVariables
  >(module, banUserPolicyQuery);

  const { authUser } = useContext(AppContext);
  const anonymousUserId = -1;
  const { isAnonymous } = useRegistrationStatus();

  if (
    isAnonymous ||
    textLoading ||
    banUserLoading ||
    banPoliciesLoading ||
    user?.uid === anonymousUserId ||
    user?.uid === authUser?.uid ||
    banUserData?.user?.banned ||
    banUserData?.user?.deleted ||
    !checkPolicy(banPoliciesData?.self.userPolicies.canBanUsers)
  ) {
    return null;
  }
  /**
   * Renders member details for the member being banned.
   *
   * @param user details of the user.
   */

  return (
    <>
      {isModalAction ? (
        <Button
          data-testid="PrivateMessageAbuseActionsButton"
          size="lg"
          variant={variant}
          onClick={() => {
            onHideSubjectModal(true);
            setShowBanModal(true);
          }}
        >
          {formatMessage('title')}
        </Button>
      ) : (
        <Dropdown.Item onClick={() => setShowBanModal(true)} data-testid="MessageActionBanMember">
          {formatMessage('title')}
        </Dropdown.Item>
      )}
      {showBanModal && (
        <BanUserDetails
          show={showBanModal}
          onHide={() => setShowBanModal(false)}
          user={user}
          isModalAction={isModalAction}
          onCloseSubjectModal={() => {
            setTimeout(() => onHideSubjectModal(false), 500);
            onCloseSubjectModal();
          }}
        />
      )}
    </>
  );
};

export default MessageActionBanMember;
