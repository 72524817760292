import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import type { User } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import type { ReactElement } from 'react';
import React from 'react';
import { Modal, useClassNameMapper } from 'react-bootstrap';
import type {
  BanUserPolicyQuery,
  BanUserPolicyQueryVariables,
  UserStatusQuery,
  UserStatusQueryVariables,
  UserViewFragment
} from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import BanUserForm from '../BanUserForm/BanUserForm';
import banUserPolicyQuery from '../BanUserPolicy.query.graphql';
import useStatusQuery from '../UserStatus.query.graphql';
import UserDetails from '../UserDetails/UserDetails';

interface Props {
  /**
   * The banning user
   */
  user: UserViewFragment;
  /**
   * Whether the modal is currently being displayed
   */
  show: boolean;
  /**
   * Callback function when the modal is hidden or cancelled
   *
   * @callback
   */
  onHide?: () => void;
  /**
   * Whether the component is rendered from a modal.
   */
  isModalAction?: boolean;
  /**
   * Callback function for toggling the display for the modal.
   */
  onCloseSubjectModal?: () => void;
}

/**
 * Ban User Details and Modal
 *
 * @author Elamaran P
 */

const BanUserDetails: React.FC<React.PropsWithChildren<Props>> = ({
  user,
  show,
  onHide,
  isModalAction = false,
  onCloseSubjectModal
}): ReactElement => {
  const cx = useClassNameMapper();

  const { formatMessage, loading: textLoading } = useTranslation(EndUserComponent.BAN_USER_DETAILS);

  const anonymousUserId = -1;

  const { data: banPoliciesData, loading: banPoliciesLoading } = useQueryWithTracing<
    BanUserPolicyQuery,
    BanUserPolicyQueryVariables
  >(module, banUserPolicyQuery);

  const {
    data: banUserData,
    refetch,
    loading: banUserLoading
  } = useQueryWithTracing<UserStatusQuery, UserStatusQueryVariables>(module, useStatusQuery, {
    variables: {
      id: user.id
    }
  });

  if (
    user.uid === anonymousUserId ||
    textLoading ||
    banPoliciesLoading ||
    !checkPolicy(banPoliciesData?.self.userPolicies.canBanUsers) ||
    banUserLoading ||
    banUserData?.user?.banned ||
    banUserData?.user?.deleted
  ) {
    return null;
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
        if (isModalAction) {
          onCloseSubjectModal();
        }
      }}
      size="sm"
      backdrop="static"
      centered
      className={cx('lia-g-locked-modal')}
      dialogClassName={cx('lia-g-locked-modal-dialog')}
      contentClassName={cx('lia-g-locked-modal-content')}
    >
      <Modal.Header
        className={cx('lia-g-locked-modal-header')}
        closeButton
        data-testid="UserProfileActionBanMember"
      >
        <Modal.Title>{formatMessage('title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={cx('lia-g-locked-modal-body')}>
        <div className={cx('small lia-g-mb-20')}>{formatMessage('banMemberWarning')}</div>
        <UserDetails banUser={user} />
        <BanUserForm
          user={user as User}
          isSingleMemberBan
          onCloseBanModal={() => {
            refetch();
            onHide();
            if (isModalAction) {
              onCloseSubjectModal();
            }
          }}
        ></BanUserForm>
      </Modal.Body>
    </Modal>
  );
};

export default BanUserDetails;
