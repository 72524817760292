import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useMessagePolicies from '@aurora/shared-client/components/messages/useMessagePolicies';
import useSeoProperties from '@aurora/shared-client/components/seo/useSeoProperties';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import type {
  MessageEditPagesAndParams,
  MessagePageOrReplyPageAndParams
} from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import {
  ContentWorkflowState,
  PostMessageType
} from '@aurora/shared-generated/types/graphql-schema-types';
import ContentWorkflowQuery from '@aurora/shared-client/components/contentWorkflow/ContentWorkflow.query.graphql';
import type {
  ContentWorkflowPropertiesQuery,
  ContentWorkflowPropertiesQueryVariables,
  ContextMessageFragment
} from '@aurora/shared-generated/types/graphql-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import ConversationStyleBehaviorHelper from '../../../helpers/boards/ConversationStyleBehaviorHelper';
import { getMessageRouteAndParams } from '../../../helpers/messages/MessageHelper/MessageHelper';
import type { MessageEditingState } from '@aurora/shared-client/helpers/ui/GlobalState';
import useGlobalState, {
  EditorLocation,
  GlobalStateType
} from '@aurora/shared-client/helpers/ui/GlobalState';
import type { MessageContentWorkflowFragment } from '../../../types/graphql-types';
import { isDraft } from '../../contentWorkflow/ContentWorkflowActionHelper';
import useTranslation from '../../useTranslation';
import MessageActionEditDraft from '../MessageActionEditDraft/MessageActionEditDraft';
import type { MessageActionType } from '../types';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';

interface Props extends MessageActionType {
  /**
   * Whether to edit the message inline or go to the full edit page.
   */
  inline?: boolean;
}

/**
 * Action to edit a message.
 *
 * @constructor
 *
 * @author Patricio Poratto, Adam Ayres
 */
const MessageActionEdit: React.FC<React.PropsWithChildren<Props>> = ({ message, inline }) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_EDIT
  );
  const { Link } = useEndUserRoutes();
  const { getCaseSensitivePath } = useSeoProperties();
  const { route, params } = getMessageRouteAndParams(message);
  const [, setMessageEditingState] = useGlobalState(GlobalStateType.MESSAGE_EDITING_STATE);
  const tenant = useContext(TenantContext);
  const { isAnonymous } = useRegistrationStatus();
  const { data: policiesData, loading: policiesLoading } = useMessagePolicies(
    module,
    {
      id: message.id,
      useCanFreezeTkbArticle: true,
      useCanEdit: true
    },
    isAnonymous || !message || IdConverter.isOptimistic(tenant, message?.id)
  );

  const { data: nodeData, loading: nodeDataLoading } = useQueryWithTracing<
    ContentWorkflowPropertiesQuery,
    ContentWorkflowPropertiesQueryVariables
  >(module, ContentWorkflowQuery, {
    variables: { id: message?.board?.id }
  });

  const supportsContentWorkflow = checkPolicy(nodeData?.coreNode?.cwaProperties?.cwaEnabled);

  if (textLoading || policiesLoading || nodeDataLoading) {
    return null;
  }

  const { messageEditPage, isContentWorkflowSupported } =
    ConversationStyleBehaviorHelper.getInstance(message.board);

  const isScheduledForPublish =
    (message as MessageContentWorkflowFragment).contentWorkflow?.state ===
    ContentWorkflowState.ScheduledForPublish;

  const canRecall = (message as MessageContentWorkflowFragment).contentWorkflow?.userContext
    ?.canRecall;

  const localEditState: MessageEditingState = {
    messageId: message.id,
    postMessageType: PostMessageType.Edit,
    editorLocation: EditorLocation.INLINE
  };

  const isRootMessage = message.depth === 0;

  const canFreezeArticle = checkPolicy(policiesData?.message?.messagePolicies?.canFreezeTkbArticle);
  const canEditMessage = checkPolicy(policiesData?.message?.messagePolicies?.canEdit);
  const canEdit = message.editFrozen
    ? canEditMessage && canFreezeArticle
    : isContentWorkflowSupported && !inline && isRootMessage && supportsContentWorkflow
    ? (message as MessageContentWorkflowFragment).contentWorkflow?.userContext?.canEdit
    : canEditMessage;

  /**
   * Render Message Editor
   */
  function renderMessageEdit(): React.ReactElement {
    return (
      <Link<MessageEditPagesAndParams>
        route={messageEditPage}
        params={{
          boardId: getCaseSensitivePath(message.board.displayId),
          messageSubject: getCaseSensitivePath(UrlHelper.determineSlugForMessagePath(message)),
          messageId: message.uid.toString()
        }}
        passHref
        legacyBehavior={true}
      >
        <Dropdown.Item data-testid="MessageActionEdit">
          {isDraft(message) ? formatMessage('titleDraft') : formatMessage('title')}
        </Dropdown.Item>
      </Link>
    );
  }

  /**
   * Render Message Reply Editor
   */
  function renderMessageReplyEdit(): React.ReactElement {
    return (
      <Dropdown.Item
        data-testid="MessageActionReplyEdit"
        onClick={(): void => setMessageEditingState(localEditState)}
      >
        {isDraft(message) ? formatMessage('titleDraft') : formatMessage('title')}
      </Dropdown.Item>
    );
  }

  return (
    <>
      {canEdit && isRootMessage && renderMessageEdit()}
      {canEdit && !isRootMessage && inline && renderMessageReplyEdit()}
      {canEdit && !isRootMessage && !inline && (
        <Link<MessagePageOrReplyPageAndParams>
          route={route}
          params={params}
          passHref
          legacyBehavior={true}
        >
          {renderMessageReplyEdit()}
        </Link>
      )}
      {isScheduledForPublish && canRecall && (
        <MessageActionEditDraft asDropdownItem message={message as ContextMessageFragment} />
      )}
    </>
  );
};

export default MessageActionEdit;
