import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import type { User } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { Dropdown, useClassNameMapper } from 'react-bootstrap';
import useTranslation from '../../useTranslation';
import { MessageActionEmailUserVariant } from './enums';
import localStyles from './MessageActionEmailUser.module.pcss';

interface Props {
  /**
   * The user or list of users to whom the email is to be sent
   */
  emailRecipients: User | User[];
  /**
   * Whether the user is a reporter
   */
  isReporter?: boolean;
  /**
   * Callback function when the button is clicked
   */
  onClick?: () => void;
  /**
   * Variant to determine whether to render a Dropdown.Item or a Button
   */
  variant?: MessageActionEmailUserVariant;
}

/**
 * Drop-down item which renders email user action button. On click of this button, an email is sent to the user
 *
 * @author Be.Abhijith
 */
const MessageActionEmailUser: React.FC<React.PropsWithChildren<Props>> = ({
  emailRecipients,
  isReporter = false,
  onClick,
  variant = MessageActionEmailUserVariant.DROPDOWN
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_EMAIL_USER
  );
  const cx = useClassNameMapper(localStyles);
  const mailToEmails = Array.isArray(emailRecipients)
    ? emailRecipients.map(user => user.email).join(',')
    : emailRecipients?.email;

  if (textLoading || !mailToEmails) {
    return null;
  }

  const mailtoHref = `mailto:${mailToEmails}`;

  return variant === MessageActionEmailUserVariant.DROPDOWN ? (
    <Dropdown.Item
      as="a"
      className={cx('lia-mailto-item')}
      href={mailtoHref}
      target="_blank"
      data-testid="MessageActionEmailUserDropdown"
    >
      {formatMessage('emailUser', { isReporter })}
    </Dropdown.Item>
  ) : (
    <Button
      as="a"
      size="lg"
      variant={ButtonVariant.SECONDARY}
      onClick={onClick}
      href={mailtoHref}
      target="_blank"
      data-testid="MessageActionEmailUserButton"
    >
      {formatMessage('emailAllReporters')}
    </Button>
  );
};

export default MessageActionEmailUser;
